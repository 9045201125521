@import "../../App.scss";

.feature-control {

    display: flex;
    flex-direction: column;
    width: 100%;

    .feature-main-title {
        font-size: 24px;
        color: var(--text-black-color);
        font-family: $fontMedium;
    }

    .feature-main-desc {
        font-size: 16px;
        color: var(--text-black-color);
        font-family: $fontRegular;
    }

    .feature-form-control-hr9-iframe{
        padding: 16px;
    }

    .feature-textarea-description{
        font-size: 11px !important;
        border: 1px solid #e4e7ec;
        padding: 6px;
        border-radius: 4px;
        background-color: #e4e7ec;
    }

    .feature-form-control {
        width: 100%;
        margin-top: 36px;
        display: grid;
        grid-template-columns: 50% 50%;
        overflow-wrap: break-word;
        text-wrap: wrap;

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }
        @include media-breakpoint-down(sm) {
            grid-template-columns: 100%;
            margin-top: 0px;
        }

        .feature-form-left-control {
            display: flex;
            flex-direction: column;
            margin-left: 36px;

            @include media-breakpoint-down(md) {
                margin-left: 0px;
            }
        }

        .dashboard-form-item-submit-control {
            margin-top: 36px;
            display: inline-flex;
            flex-direction: column;
            width: auto;

            .dashboard-form-item-border-control {
                display: flex;
                flex-direction: column;
                border: none;
                padding: 18px;
                border-radius: 12px;
                width: fit-content;
                box-shadow: 0px 0px 12px var(--border-input);
            }

            .dashboard-form-item-submit-item {
                display: flex;
                flex-direction: column;
                margin-top: 6px;
                margin-bottom: 6px;
            }

            .dashboard-form-item-submit-title {
                font-size: 13px;
                font-family: $fontRegular;
                color: var(--text-gray-color);
                overflow-wrap: break-word;
            }
            .dashboard-form-item-submit-desc-sub {
                font-size: 10px;
                font-family: $fontRegular;
                color: var(--text-gray-color);
                overflow-wrap: break-word;
            
            }
            .dashboard-form-item-submit-desc {
                font-size: 16px;
                margin-bottom: 8px;
                font-family: $fontMedium;
                color: var(--text-black-color);
                overflow-wrap: break-word;
            }
        }

        .dashboard-form-item-control {
            margin-top: 36px;
        }

        .dashboard-form-click-2-columns {
            display: flex;
            flex-direction: column;

        }

        .dashboard-form-item {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            width: 100%;

            @include media-breakpoint-down(md){
                width: 100%;
            }



            .dashboard-form-item-title {
                font-size: 12px;
                font-family: $fontMedium;
                color: var(--text-black-color);
            }
            .dashboard-form-item-desc {
                font-size: 10px;
                font-family: $fontRegular;
                color: var(--text-black-color);
                margin-bottom: 6px;
            }
            .dashboard-input {
                font-size: 14px;
                font-family: $fontMedium;
                height: 46px;
                color: var(--text-black-color);
            }

            .dashboard-form-item-error {
                font-size: 13px;
                font-family: $fontRegular;
                color: var(--hover-primary-color);
            }

        }

    }

    .feature-loading-result {
        display: flex;
        min-height: 90vh;
        width: 100%;

        .feature-loading-control {
            margin: auto;
            display: flex;
            flex-direction: column;
        }
    }

    .feature-button-control{
        width: 100%;
        display: flex;
    }

    .feature-button-resume {
        min-height: 40px;
        font-size: 14px;
        width: 250px;
        font-family: $fontMedium;
        background-color: var(--button-secondary);
        border-radius: 8px;
        color: var(--text-button-secondary-color);
        border: 2px solid var(--button-primary);
        box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
        
    }

    .feature-submit-button {
        border-radius: 8px;
        height: 45px;
        background-color: var(--button-primary);
        color: var(--text-button-color);
        font-size: 16px;
        border: none;
        width: 100%;
        padding: 4px 15px;
        font-family: $fontMedium;
        margin-top: 24px;

        &:hover {
            background-color: var(--button-primary);
            box-shadow: 0px 0px 5px var(--border-input);
        }

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }

    .feature-credit-usage-control{

        display: flex;
        margin-top: 16px;

        .feature-credit-usage-icon{
            font-size: 16px;
            color: rgb(255, 183, 0);
        }

        .feature-credit-usage-text{
            font-size: 14px;
            color: var(--text-black-color);
            margin-left: 8px;
            font-family: $fontRegularLINE;
        }
        .feature-credit-usage-text-bold{
            font-size: 14px;
            color: var(--text-black-color);
            margin-left: 8px;
            font-family: $fontMedium;
        }

        .feature-credit-usage-text-bold-line-through{
            font-size: 14px;
            color: #818181;
            margin-left: 8px;
            text-decoration: line-through;
            font-family: $fontMedium;
        }
    }

    .feature-submit-button-gray {
        border-radius: 26px;
        height: 45px;
        background-color: var(--border-input);
        color: var(--text-button-color);
        font-size: 16px;
        border: none;
        min-width: 200px;
        font-family: $fontMedium;
        margin-top: 24px;

        &:hover {
            background-color: var(--border-input);
        }
    }

}