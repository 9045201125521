@import "../../../App.scss";

.hr9-menu-main {
    display: flex;
    width: 100%;
    min-height: 100vh;

    .hr9-menu-control {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        .hr9-menu-list{
            width: 100%;
            max-width: 768px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(sm){
                max-width: 250px;
            }

            .hr9-menu-header{
                font-size: 16px;
                text-align: center;
                font-family: $fontMedium;
                color: #000;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 24px;
            }

            .hr9-menu-button{
                background-color: var(--primary-color);
                border-radius: 24px;
                border:none;
                margin-left: 4px;
                margin-right: 4px;
                width: 100%;
                height: 45px;
                font-size: 14px;
                color: white;
                margin-top: auto;
                margin-bottom: auto;
                font-family: $fontMedium;
    
                @include media-breakpoint-down(md){
                    font-size: 12px;
                    height: 40px;
                    margin-bottom: 12px;
                }
    
                @include media-breakpoint-down(sm){
                    font-size: 12px;
                    height: 40px;
                    margin-bottom: 4px;
                }
            }
        }

        
    }

}