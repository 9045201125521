@import "../../../../App.scss";


.carousel-landing-control {
    z-index: 3 !important;
    height: 100%;

    @include media-breakpoint-down(md) {
        height: 40vh;
    }
    @include media-breakpoint-down(sm) {
        height: 40vh;
    }
    .carousel__slider--vertical {
        overflow: hidden;

        @include media-breakpoint-down(md) {
            height: 40vh;
        }
        @include media-breakpoint-down(sm) {
            height: 40vh;
        }
    }

    .carousel__slider-tray-wrap--vertical{
        height:  50vh !important;
    }

    .carousel__slide--visible{
        height: auto !important;
    }

    .carousel__inner-slide{
        height: fit-content;
    }

    .carousel-dots-control {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        right: 0;
        margin-right: -10%;
        margin-top: 10vh;

        @include media-breakpoint-down(lg) {
            margin-right: -3%;
        }

        @include media-breakpoint-down(md) {
            margin-top: 2vh;
            width: auto;
            height: auto;
        }

        button {
            margin-top: 6px;
            margin-bottom: 6px;
            background-color: white;
            margin-left: auto;
            margin-right: auto;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: none !important;

            @include media-breakpoint-down(md) {
                margin-top: auto;
                margin-bottom: auto;
                width: 30px;
                height: 30px;
                scale: 0.4;
                -webkit-border-radius: 50%;
                /* Safari and older Chrome, older iOS */
                -moz-border-radius: 50%;
                /* Older Firefox */
                border-radius: 50%;
            }


        }


        .carousel__dot--selected {
            background-color: var(--primary-color);
            width: 17px;
            height: 17px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;


            @include media-breakpoint-down(md) {
                margin-top: auto;
                margin-bottom: auto;
                width: 30px;
                height: 30px;
                scale: 0.7;
                -webkit-border-radius: 50%;
                /* Safari and older Chrome, older iOS */
                -moz-border-radius: 50%;
                /* Older Firefox */
                border-radius: 50%;
            }
        }

    }

    .carousel-landing-img {
        width: auto;
        height: 350px;
        object-fit: contain;
        margin-left: auto;

        @include media-breakpoint-down(lg) {
            height: 240px;
            margin-right: 5%;
        }

        @include media-breakpoint-down(md) {
            height: 230px;
            margin-right: auto;
        }

        @include media-breakpoint-down(sm) {
            height: 200px;
            margin-right: auto;
        }
    }

    .carousel-landing-item-control {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .carousel-landing-item-text-control {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        padding-left: 15%;

        @include media-breakpoint-down(lg) {
            padding-left: 3%;
        }

        @include media-breakpoint-down(md) {
            padding-left: 0%;
            height: fit-content;
        }
    }

    .carousel-landing-item-title {
        font-size: 24px;
        color: var(--text-carousel-color);
        font-family: $fontMedium;

        @include media-breakpoint-down(lg) {
            font-size: 17px;
        }

        @include media-breakpoint-down(md) {
            font-size: 17px;
        }

    }

    .carousel-landing-item-desc {
        font-size: 18px;
        color: var(--text-carousel-color);
        font-family: $fontRegular;

        @include media-breakpoint-down(lg) {
            font-size: 13px;
        }

        @include media-breakpoint-down(md) {
            font-size: 13px;
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            flex-wrap: wrap;
        }
    }

    
}