@import "../../App.scss";

$primary-color: var(--primary-color);
$primary-blue-color: var(--primary-blue-color);
$hover-primary-color: var(--hover-primary-color);
$secondary-color: var(--secondary-color);
$text-black-color: var(--text-black-color);
$text-white-color: var(--text-white-color);
$text-gray-color: var(--text-gray-color);
$text-button-color: var(--text-button-color);
$text-primary-soft-color: var(--text-primary-soft-color);
$text-button-secondary-color: var(--text-button-secondary-color);
$pro-color: var(--pro-color);
$brown-color: var(--brown-color);
$link-color: var(--link-color);
$text-blue-color: var(--text-blue-color);
$border-line: var(--border-line);
$border-input: var(--border-input);
$button-primary: var(--button-primary);
$button-secondary: var(--button-secondary);
$error-color: var(--error-color);
$bg-color: var(--bg-color);


.standard-landing-main {
    display: block;
    width: auto;
    min-height: 100vh;


    .standard-landing-header-section {
        font-size: 34px;
        font-family: $fontMedium;
    }

    .standard-landing-sub-header-section {
        font-size: 20px;
        font-family: $fontRegularLINE;

        @include media-breakpoint-down(md){
            font-size: 18px;
        }
    }

    .standard-order-right{
        order: 0;

        @include media-breakpoint-down(md){
            order: 1;
        }
    }
    .standard-landing-description-section {
        font-size: 16px;
        font-family: $fontRegularLINE;

        @include media-breakpoint-down(md){
            font-size: 13px;
        }
    }

    .standard-landing-control {
        display: flex;
        flex-direction: column;

        .standard-landing-cover-control {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            min-height: 30vh;
            width: 100%;

            @include media-breakpoint-down(md){
                grid-template-columns: repeat(1, 1fr);
            }

            .standard-landing-cover-left-control {
                display: flex;
                flex-direction: column;
                margin-top: auto;
                margin-bottom: auto;
                padding: 0px 0px 0px 36px;

                @include media-breakpoint-down(md){
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 48px;
                    padding-bottom: 24px;
                }

                .standard-landing-cover-button-control {
                    display: flex;
                    margin-top: 24px;

                   

                    .standard-landing-button-primary {
                        width: 250px;
                        height: 50px;
                        border-radius: 14px;
                        background-color: $button-primary;
                        color: white;
                        border: none;
                        margin-right: 6px;
                        @include media-breakpoint-down(md){
                            width: 150px;
                        }

                        &:hover {
                            background-color: white;
                            color: $button-primary;
                            border: 1px solid $button-primary;
                        }
                    }

                    .standard-landing-button {
                        width: 250px;
                        height: 50px;
                        margin-left: 6px;
                        border-radius: 14px;
                        background-color: white;
                        color: $button-primary;
                        border: 1px solid $button-primary;

                        @include media-breakpoint-down(md){
                            width: 150px;
                        }

                        &:hover {
                            background-color: $button-primary;
                            color: $white;
                        }
                    }
                }

                .standard-landing-header-text {
                    font-size: 24px;
                    font-family: $fontRegularLINE;
                    color: $text-black-color;
                }

                .standard-landing-header-text-primary {
                    font-size: 36px;
                    line-height: 1.5em;
                    font-family: $fontMedium;
                    color: $primary-color;
                }

                .standard-landing-description-flex {
                    display: flex;

                    @include media-breakpoint-down(md){
                        flex-wrap: wrap;
                    }

                    .standard-landing-description {
                        font-size: 20px;
                        margin-left: 4px;
                        margin-right: 4px;
                        font-family: $fontRegular;
                        color: $text-black-color;
                    }

                    .standard-landing-description-bold {
                        font-size: 20px;
                        margin-left: 4px;
                        margin-right: 4px;
                        font-family: $fontMedium;
                        color: $primary-color;
                    }
                }


            }

            .standard-landing-cover-right-control {
                display: flex;
                flex-direction: column;
                margin-top: auto;
                margin-bottom: auto;
                padding: 2% 0px;
                padding-right: 5%;

                @include media-breakpoint-down(md){
                    padding-right: 0%;
                    order: 0;
                }

                .standard-landing-cover-right-carousel{
                    height: 500px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    overflow: hidden;
                    display: block;
                }

                .standard-landing-cover-img {
                    width: 400px;
                    height: auto;
                    margin: auto;
                    margin-left: auto;
                    margin-right: auto;
                    object-fit: contain;

                    @include media-breakpoint-down(md){
                        width: 100%;
                        order: 0;
                    }
                }
            }
        }

        .standard-landing-section-2-main {
            background-color: $primary-color;
            padding-top: 2%;
            padding-bottom: 2%;
            display: flex;

            @include media-breakpoint-down(md){
                padding-top: 36px;
                padding-bottom: 36px;
            }

            .standard-landing-section-2-control {
                max-width: 992px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 32px;
                padding-right: 32px;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(lg) {
                    max-width: 768px;
                    padding-left: 0px;
                    padding-right: 0px;
                }

                @include media-breakpoint-down(md) {
                    max-width: 552px;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 400px;
                }

                .standard-landing-section-2-header {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    color: white;
                }

                .standard-landing-section-2-sub-header {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 4px;
                    color: white;
                }

                .standard-landing-section-2-img {
                    width: 60%;
                    height: auto;
                    object-fit: contain;
                    margin-top: 24px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .standard-landing-section-2-button {
                    width: 400px;
                    height: 50px;
                    background-color: white;
                    border-radius: 14px;
                    color: $button-primary;
                    font-size: 18px;
                    font-family: $fontMedium;
                    border: none;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 24px;
                }
            }
        }

        .standard-landing-section-left-main {
            padding-top: 5%;
            padding-bottom: 5%;
            background-color: white;

            @include media-breakpoint-down(md){
                padding-top: 36px;
                padding-bottom: 36px;
            }

            .standard-landing-section-left-control {
                max-width: fit-content;
                margin-left: auto;
                margin-right: auto;
                padding-left: 5%;
                padding-right: 5%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);


                @include media-breakpoint-down(lg) {
                    max-width: 768px;
                    padding-left: 0px;
                    padding-right: 0px;
                }

                @include media-breakpoint-down(md) {
                    max-width: 552px;
                    grid-template-columns: repeat(1, 1fr);
                }

                @include media-breakpoint-down(sm) {
                    max-width: 400px;
                }

                .standard-landing-section-left-item {
                    display: flex;
                    flex-direction: column;
                    margin-top: auto;
                    margin-bottom: auto;

                    .standard-landing-section-left-header{
                        text-align: left;
                        color: $text-black-color;
                    }
                    .standard-landing-section-left-sub-header{
                        text-align: left;
                        color: $text-black-color;
                        margin-top: 16px;
                    }
                }

                .standard-landing-section-right-item {
                    display: flex;
                    flex-direction: column;
                    margin-top: auto;
                    margin-bottom: auto;

                   
                    .standard-landing-section-right-item-img{
                        width: 60%;
                        height: auto;
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-left: auto;
                        margin-right: auto;
                        object-fit: contain;

                        @include media-breakpoint-down(md){
                            margin-top: 24px;
                            margin-bottom: 24px;
                            width: 100%;
                        }
    
                    }
                }

            }


        }
    }
}