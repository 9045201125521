@import "../../App.scss";
.dashboard-control {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    background-color: var(--bg-color);
    .dashboard-box-main-control {
        margin-top: 16px;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .MuiBox-root {
        padding: 0px;
    }
    .MuiListItemIcon-root {
        justify-content: center !important;
    }
    .MuiDrawer-paper {
        z-index: 1;
        background-color: var(--primary-blue-color);
    }
    .MuiDrawer-paper {
        box-shadow: none !important;
        @include media-breakpoint-down(md) {
            box-shadow: 0px 0px 10px var(--border-input) !important;
        }
    }
    .MuiDrawer-docked {
        display: block;
        position: relative;
        background-color: var(--primary-blue-color);
        @include media-breakpoint-down(md) {
            display: block;
            position: absolute;
            box-shadow: 0px 0px 10px var(--border-input);
        }
    }
    .dashboard-menu-control {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: var(--bg-color);
        .dashboard-app-bar {
            background-color: var(--primary-color);
            .dashboard-app-bar-logo-company {
                width: 140px;
                height: auto;
                object-fit: contain;
            }
        }
        .list-group-item {
            border: none !important;
            padding: 0px;
            border-radius: 0px;
            background-color: var(--bg-color);
            &:hover {
                background-color: var(--text-primary-soft-color);
                color: white;
            }
            &:hover .dashboard-list-icon {
                color: white;
            }
            &:hover .dashboard-list-icon-arrow {
                color: white;
            }
        }
        .dashboard-list-item-group {
            border-radius: 0px;
            .MuiListItemText-primary {
                font-family: $fontMedium !important;
            }
        }
        .dashboard-list-group {
            border-radius: 0px;
            padding: 16px 0px;
        }
        .dashboard-list-group-active {
            background-color: var(--primary-color);
            color: white;
            padding: 16px 0px;
        }
        .dashboard-list-item {
            background-color: white;
            color: var(--brown-color);
            &:hover {
                background-color: var(--text-primary-soft-color);
                color: white;
            }
            .MuiListItemText-primary {
                font-family: $fontRegular !important;
            }
        }
        .dashboard-list-item-active {
            background-color: var(--primary-color);
            color: white;
        }
        .dashboard-ai-text {
            font-size: 12px;
            color: var(--primary-blue-color);
            margin-left: 12px;
            .dashboard-ai-img {
                width: 25px;
                height: 25px;
                object-fit: contain;
            }
        }
        .dashboard-premium-badge-control {
            background-color: var(--pro-color);
            border-radius: 26px;
            color: white;
            font-family: $fontMedium;
            font-size: 14px;
            padding: 3px 12px;
        }
        .dashboard-list-icon-arrow {
            font-size: 13px;
            color: var(--brown-color);
        }
        .dashboard-list-icon {
            font-size: 22px;
            text-align: center;
            color: var(--brown-color);
        }
        .dashboard-list-icon-active {
            font-size: 22px;
            text-align: center;
            color: white;
        }
        .dashboard-list-group-text {
            font-size: 17px;
        }
        .dashboard-list-text {
            margin-left: 24px;
            font-size: 17px;
            font-weight: 400;
        }
    }
    .dashboard-ai-show-control {
        cursor: pointer;
        position: fixed;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-right: 36px;
        animation: bounce 2s infinite;
        @include media-breakpoint-down(md) {
            display: none;
        }
        .dashboard-ai-show-img {
            width: 70px;
            height: auto;
            object-fit: contain;
            margin-bottom: 24px;
            margin-left: auto;
            margin-right: auto;
        }
        .dashboard-ai-quote {
            min-width: 200px;
            min-height: 50px;
            background-color: var(--primary-color);
            border-radius: 26px;
            display: flex;
            padding: 16px;
            margin-left: auto;
            margin-right: auto;
            .dashboard-ai-quote-text {
                font-size: 14px;
                color: white;
                font-family: $fontMedium;
                margin: auto;
            }
        }
    }
    @keyframes bounce {
        0% {
            transform: translateY(0);
            /* Start and end position: no vertical movement */
        }
        50% {
            transform: translateY(-15px);
            /* Bounce downwards */
        }
        100% {
            transform: translateY(0px);
            /* Bounce downwards */
        }
    }
}