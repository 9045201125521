@import "../node_modules/bootstrap/scss/bootstrap.scss";
$fontBold: "LINESeedSansTH_He";
$fontRegular: "Sarabun-Regular";
$fontRegularLINE: "LINESeedSansTH_Rg";
$fontMedium: "LINESeedSansTH_Bd";
$fontLight: "Sarabun-Light";
.error-text-size {
    margin-top: 4px;
    font-size: 13px;
    color: var(--error-color);
    font-family: $fontMedium;
}

a {
    cursor: pointer;
    color: var(--link-color);
    margin-top: 16px;
    font-family: $fontMedium;
    font-size: 14px;
    text-decoration: underline;
}

body {
    font-family: $fontRegular !important;
    background-color: white;
    scrollbar-width: none;
}

.form-control:focus {
    border: none !important;
    box-shadow: 0 0 5px var(--primary-color) !important;
}

.form-select:focus {
    border: none !important;
    box-shadow: 0 0 5px var(--primary-color) !important;
}

.MuiFormLabel-root {
    font-family: $fontRegular !important;
}

.MuiChip-label {
    font-family: $fontMedium !important;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.searching-load {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.searching-text {
    font-size: 16px;
    font-family: $fontMedium;
    margin-top: 4px;
}

.button-default {
    background-color: var(--button-primary);
    color: var(--text-white-color);
    height: 46px;
    font-family: $fontMedium;
    font-size: 16px;
    min-width: 100px;
    border: var(--border-line) 2px solid;
    border-radius: 8px;
    &:hover {
        background-color: var(--button-primary);
    }
}

.link-default {
    font-size: 16px;
    font-family: $fontRegular;
    color: var(--primary-color);
    text-decoration: underline;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.MuiDrawer-paper {
    background-color: var(--primary-blue-bg-color) !important;
}

@font-face {
    font-family: $fontBold;
    src: local("LINESeedSansTH_He"), url(../public/fonts/LINESeedSansTH_He.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("LINESeedSansTH_Bd"), url(../public/fonts/LINESeedSansTH_Bd.ttf) format("truetype");
}

@font-face {
    font-family: $fontRegularLINE;
    src: local("LINESeedSansTH_Rg"), url(../public/fonts/LINESeedSansTH_Rg.ttf) format("truetype");
}

@font-face {
    font-family: $fontRegular;
    src: local("Sarabun-Regular"), url(../public/fonts/Sarabun-Regular.ttf) format("truetype");
}

@font-face {
    font-family: $fontLight;
    src: local("Sarabun-Light"), url(../public/fonts/Sarabun-Light.ttf) format("truetype");
}