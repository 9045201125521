@import "../../../App.scss";

.footer-standard{
    background-color: black;
    min-height: 100px;
    width: 100%;
    padding-top: 36px;
    padding-bottom: 36px;

    .footer-standard-control{
        display: grid;
        grid-template-columns: 30% 70%;

        .footer-standard-logo-control{
            display: flex;
            flex-direction: column;

            .footer-standard-logo-img{
                width: 200px;
                height: auto;
                object-fit: contain;
            }
        }

    }
}