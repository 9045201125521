@import "../../App.scss";

.hr9-main {
    display: flex;
    width: 100%;
    min-height: 100vh;

    .hr9-control {
        margin: auto;
        display: flex;
        flex-direction: column;

        .hr9-spinner {
            width: 30px;
            height: 30px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 14px;
        }

        .hr9-text{
            font-size: 15px;
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
        }
    }

}