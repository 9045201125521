@import "../../../../App.scss";
.talent-sauce-header-mobile-main {
    width: 100vw;
    display: flex;
    flex-direction: column;
    .talent-sauce-header-mobile-button {
        height: 45px;
        width: 100vw;
        color: var(--primary-color);
        font-family: $fontMedium;
        font-size: 16px;
        &:hover {
            background-color: var(--primary-color);
            color: white;
        }
    }
    .MuiFormControl-root {
        width: 100%;
        font-family: $fontMedium;
    }
    .MuiDrawer-paper {
        background-color: var(--primary-blue-color);
        width: 100vw !important;
    }
    .MuiDrawer-root {
        width: 100vw;
    }
}

.talent-sauce-header-main {
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
    display: flex;
    background-color: transparent;
    @include media-breakpoint-down(md) {
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
    .MuiDrawer-paper {
        background-color: var(--primary-blue-color);
        width: 100vw !important;
    }
    .MuiDrawer-root {
        width: 100vw;
    }
    .talent-sauce-header-logo {
        width: auto;
        height: 50px;
        object-fit: contain;
        cursor: pointer;
        @media screen and (max-width: 1440px) {
            height: 30px;
        }
        @include media-breakpoint-down(lg) {
            height: 35px;
        }
        @include media-breakpoint-down(md) {
            height: 40px;
        }
    }
    .MuiToolbar-root {
        padding-left: 0px;
        padding-right: 0px;
        @include media-breakpoint-down(md) {
            padding-left: 14px;
            padding-right: 14px;
        }
    }
    .MuiAppBar-root {
        background-color: transparent;
        color: var(--text-white-color);
        box-shadow: none;
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        padding-left: 2%;
        padding-right: 2%;
        @include media-breakpoint-down(md) {
            color: var(--text-color);
            padding-left: 1%;
            padding-right: 1%;
        }
    }
    .MuiFormControl-root {
        min-width: fit-content;
        @include media-breakpoint-down(md) {
            width: 100%;
            font-family: $fontMedium;
            text-align: left;
        }
        .MuiSelect-select {
            font-family: $fontMedium;
            margin-left: 6px;
        }
    }
    .talent-sauce-header-lang-control {
        width: fit-content;
        border: none !important;
        font-size: 16px;
        color: white !important;
        height: 50px !important;
        @include media-breakpoint-down(xl) {
            font-size: 13px;
        }
        @include media-breakpoint-down(md) {
            color: var(--text-color);
            width: 100%;
            margin-right: auto;
        }
        .MuiSelect-icon {
            color: white !important;
            @include media-breakpoint-down(md) {
                color: white !important;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }
    }
    .talent-sauce-header-line-oa-button {
        width: fit-content;
        padding-left: 20px;
        padding-right: 20px;
        background-color: transparent;
        color: white;
        border-radius: 44px;
        margin-left: 18px;
        height: 65px;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 12px;
        font-family: $fontMedium;
        font-size: 18px;
        border: 1px solid var(--primary-color);
        &:hover {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }
        @media screen and (max-width: 1440px) {
            height: 45px;
            margin-left: 6px;
            margin-right: 6px;
            font-size: 16px;
        }
        @include media-breakpoint-down(xl) {
            font-size: 11px;
            padding-left: 17px;
            margin-left: 9px;
            padding-right: 17px;
            margin-right: 9px;
        }
        @include media-breakpoint-down(md) {
            height: 45px;
            margin-left: auto;
            margin-right: auto;
            font-size: 16px;
            color: var(--primary-color);
            width: 90%;
        }
    }
    .talent-sauce-listbox-mobile-control {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .talent-sauce-header-list-control {
        display: flex;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .talent-sauce-header-button-menu {
        cursor: pointer;
        margin-left: 18px;
        margin-right: 18px;
        color: var(--text-white-color);
        font-size: 16px;
        font-family: $fontRegularLINE;
        margin-top: auto;
        margin-bottom: auto;
        &:hover {
            color: var(--button-primary) !important;
        }
        &:active {
            background-color: transparent !important;
            color: var(--button-primary) !important;
        }
        @media screen and (max-width: 1440px) {
            margin-left: 12px;
            margin-right: 12px;
        }
        @include media-breakpoint-down(xl) {
            margin-left: 6px;
            margin-right: 6px;
            font-size: 12px;
        }
        @include media-breakpoint-down(md) {
            color: var(--text-color);
            margin-bottom: 6px;
            font-size: 14px;
        }
    }
    .talent-suace-header-hamburger {
        color: var(--text-white-color);
    }
    .talent-suace-header-button-start {
        background-color: var(--button-primary);
        color: var(--text-white-color);
        border-radius: 44px;
        font-size: 18px;
        margin-left: 18px;
        border: none;
        font-family: $fontMedium;
        height: 65px;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 47px;
        padding-right: 47px;
        margin-right: 12px;
        box-shadow: 0px 8px 12px 0px rgba(51, 51, 51, 0.12);
        &:hover {
            background-color: var(--button-primary);
        }
        &:active {
            background-color: var(--button-primary) !important;
        }
        @media screen and (max-width: 1440px) {
            height: 45px;
            margin-left: 6px;
            margin-right: 6px;
            font-size: 16px;
        }
        @include media-breakpoint-down(xl) {
            font-size: 11px;
            padding-left: 17px;
            margin-left: 9px;
            padding-right: 17px;
            margin-right: 9px;
        }
        @include media-breakpoint-down(md) {
            color: var(--text-white-color);
            margin-left: auto;
            margin-right: auto;
            margin-top: 16px;
            height: 51px;
            width: 90%;
        }
    }
}